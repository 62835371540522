import Layout from '../components/layout';
import React from 'react';

class NotFound extends React.Component {
  render() {
    return(
      <Layout location={this.props.location}>
      </Layout>
    )
  }
}

export default NotFound;
