import {graphql, Link, useStaticQuery} from 'gatsby';
import React from 'react';
import GatsbyImage from 'gatsby-image';
import burgerIcon from '../assets/icons/burger-icon.png';

export default () => {
  const images = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "logo-black.png"}) {
        childImageSharp {
          fluid(maxHeight: 80) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `);

  const [burgerShow, setBurgerShow] = React.useState(false);

  return (
    <nav role='navigation' className='navbar navbar-expand-lg'>
      <div className='container'>
        <Link to='/'>
          <GatsbyImage className='navbar-logo' fluid={images.file.childImageSharp.fluid} alt='AMG logo'/>
        </Link>
        <button className='burger-menu' onClick={() => setBurgerShow(!burgerShow)}>
          <img src={burgerIcon} alt='Show Menu'/>
        </button>
        <ul className={'navigation ' + (burgerShow ? 'visible' : '')}>
          <li className='navigationItem'>
            <Link to='/'>Inicio</Link>
          </li>
          <li className='navigationItem'>
            <Link to='/articles/'>Articulos</Link>
          </li>
          <li className='navigationItem'>
            <Link to='/directory/'>Directorio</Link>
          </li>
          <li className='navigationItem'>
            <Link to='/calendar/'>Calendario</Link>
          </li>
          {/*<li className='navigationItem'>*/}
          {/*  <Link to='/membership/'>Membresia</Link>*/}
          {/*</li>*/}
        </ul>
      </div>
    </nav>
  )
}
